<template>
    <div class="ControlsResume">
        <div class="head f-c-b">
            <div class="LeftPart f-c">
                <img class="logo" src="@/assets/Community/logo.png" alt="">
                <img class="shiliaologo" src="@/assets/Community/shiliaologo.png" alt="">
            </div>
            <el-breadcrumb class="f-c-e" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">
                    <img style="width: 16px;height: 14px;" src="@/assets/home.png" alt="">
                    <span> 当前位置： 首页</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/CommunityIndex' }">社区</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/TalentRecruitmentIndex' }">人才招聘</el-breadcrumb-item>
                <el-breadcrumb-item>个人中心</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="main">
            <div class="f-cl-c">
                <div>
                    <el-form class="f-w w--100" ref="ruleForm" :model="ruleForm" label-width="100px"
                        :rules="fillResume">
                        <el-form-item class=" w--50" v-for="(item, index) of elFormItemArr" :label="item.label"
                            :prop="item.prop" :key="index">
                            <div v-if="item.prop == 'salary'">
                                <salarySelsect @handlesalarySelsect="handlesalarySelsect" :key="new Date().getTime()"
                                    :min="ruleForm.minsalary" :max="ruleForm.maxsalary">
                                </salarySelsect>
                            </div>
                            <el-select style="width: 340px;" v-else-if="item.isSelect" v-model="ruleForm[item.prop]">
                                <el-option v-for="item1 in item.optionList" :key="item1.name" :label="item1.name"
                                    :value="item1.value"></el-option>
                            </el-select>
                            <el-input style="width: 340px;" v-else-if="item.is30WideCharacter" maxlength="30"
                                type="text" v-model="ruleForm[item.prop]"
                                :placeholder="item.placeholderValue"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="formTitle ">基本信息</div>
                <div>
                    <el-form class="f-w w--100" ref="basicInfo" :model="ruleForm" label-width="100px"
                        :rules="fillResume">
                        <el-form-item class=" w--50" v-for="(item, index) of infoElFormItemArr" :label="item.label"
                            :prop="item.prop" :key="index">
                            <el-input style="width: 340px;" v-if="item.is30WideCharacter" maxlength="30" type="text"
                                v-model="ruleForm[item.prop]" :placeholder="item.placeholderValue"></el-input>
                            <el-select style="width: 340px;" v-if="item.isSelect" v-model="ruleForm[item.prop]">
                                <el-option v-for="item1 in item.optionList" :key="item1.name" :label="item1.name"
                                    :value="item1.name"></el-option>
                            </el-select>
                            <div class="flex" v-if="item.prop == 'contactValue'">
                                <el-select style="width: 100px;" v-model="ruleForm.contactType">
                                    <el-option v-for="item1 in item.contactTypeList" :key="item1.name"
                                        :label="item1.name" :value="item1.value">
                                        <div class="f-c-b">
                                            <img v-if="item1.name == '手机'"
                                                src="../../../assets/Community/iPhoneIcon.png" alt="">
                                            <img v-else-if="item1.name == '微信'"
                                                src="../../../assets/Community/WeChatIcon.png" alt="">
                                            <img v-else-if="item1.name == 'QQ'"
                                                src="../../../assets/Community/QQIcon.png" alt="">
                                            <img v-else-if="item1.name == '邮箱'"
                                                src="../../../assets/Community/MailboxIcon.png" alt="">
                                            <span>{{ item1.name }}</span>
                                        </div>

                                    </el-option>
                                </el-select>
                                <el-input class="ml-20" style="width: 220px;"
                                    v-model="ruleForm.contactValue"></el-input>
                            </div>
                            <ChooseSecondLevelCity style="width: 340px !important;" v-if="item.isCity"
                                :city="ruleForm.workLocationList" @handleChangeCity="handleChangeCity"
                                :key="new Date().getTime()">
                            </ChooseSecondLevelCity>
                            <el-radio-group v-model="ruleForm[item.prop]" v-if="item.prop == 'sex'">
                                <el-radio label="男">男</el-radio>
                                <el-radio label="女">女</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <div class="formTitle ">工作经历</div>
                        <div class="w--100">
                            <div v-if="forumExperienceList.length != 0 && forumExperienceList">
                                <div v-for="(item, index)  of forumExperienceList" :key="index">
                                    <div class="f-w-b mb-30 boxShadow forumExperienceItem">
                                        <div class="f-c mb-25">
                                            <span class="fs-14" style="color: #606266;margin-right: 12px;">公司名称</span>
                                            <el-input disabled style="width: 340px;" type="text"
                                                v-model="item.corporateName"></el-input>
                                        </div>
                                        <div class="f-c mb-25">
                                            <span class="fs-14" style="color: #606266;margin-right: 12px;">职位名称</span>
                                            <el-input disabled style="width: 340px;" type="text"
                                                v-model="item.position"></el-input>
                                        </div>
                                        <div class="f-c mb-25">
                                            <span class="fs-14"
                                                style="color: #606266;margin-right: 12px;margin-left: 27px;">时间</span>
                                            <el-input disabled style="width: 130px;" type="text"
                                                v-model="item.intoDate"></el-input>
                                            <span class="ml-25 mr-25">——</span>
                                            <el-input disabled style="width: 130px;" type="text"
                                                v-model="item.outDate"></el-input>
                                        </div>

                                        <div class="f-c mb-25">
                                            <span class="fs-14" style="color: #606266;margin-right: 12px;">岗位薪资</span>
                                            <el-input disabled style="width: 340px;" type="text"
                                                v-model="item.salary"></el-input>
                                        </div>
                                        <div class="f-c mb-25">
                                            <span class="fs-14" style="color: #606266;margin-right: 12px;">所在城市</span>
                                            <el-input disabled style="width: 340px;" type="text"
                                                v-model="item.workLocation"></el-input>
                                        </div>
                                        <div class="f-c mb-25">
                                            <span class="fs-14" style="color: #606266;margin-right: 12px;">岗位职责</span>
                                            <el-input disabled style="width: 340px;" type="textarea"
                                                v-model="item.responsibility"></el-input>
                                        </div>
                                        <div class="f-c">
                                            <span class="fs-14" style="color: #606266;margin-right: 12px;">工作业绩</span>
                                            <el-input disabled style="width: 340px;" type="textarea"
                                                v-model="item.performance"></el-input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w--100 f-c-c">
                            <div class="addBtn f-c-c" @click="workVisibleVisible = true">
                                <span>新增工作经历</span>
                                <img class="ml-5" src="@/assets/Community/addwork.png" alt="">
                            </div>
                        </div>
                        <div class="formTitle ">教育背景</div>
                        <div class="w--100">
                            <div v-if="educationList.length != 0 && educationList">
                                <div v-for="(item, index)  of educationList" :key="index">
                                    <div class="f-w-b mb-30 boxShadow forumExperienceItem">
                                        <div class="f-c mb-25">
                                            <span class="fs-14" style="color: #606266;margin-right: 12px;">学校名称</span>
                                            <el-input disabled style="width: 340px;" type="text"
                                                v-model="item.schoolName"></el-input>
                                        </div>
                                        <!-- UnitAccount.getDegreetype(item.degree) -->
                                        <div class="f-c mb-25">
                                            <span class="fs-14" style="color: #606266;margin-right: 12px;">学历</span>
                                            <el-input disabled style="width: 340px;" type="text"
                                                v-model="item.degreeVal"></el-input>
                                        </div>
                                        <div class="f-c mb-25">
                                            <span class="fs-14"
                                                style="color: #606266;margin-right: 12px;margin-left: 27px;">时间</span>
                                            <el-input disabled style="width: 130px;" type="text"
                                                v-model="item.intakeDate"></el-input>
                                            <span class="ml-25 mr-25">——</span>
                                            <el-input disabled style="width: 130px;" type="text"
                                                v-model="item.graduateDate"></el-input>
                                        </div>
                                        <div class="f-c mb-25">
                                            <span class="fs-14" style="color: #606266;margin-right: 12px;">专业</span>
                                            <el-input disabled style="width: 340px;" type="text"
                                                v-model="item.speciality"></el-input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w--100 f-c-c">
                            <div class="addBtn f-c-c" @click="educationVisibleVisible = true">
                                <span>新增教育背景</span>
                                <img class="ml-5" src="@/assets/Community/addwork.png" alt="">
                            </div>
                        </div>
                        <div class="formTitle ">自我评价</div>
                        <el-form-item label="自我评价" prop="selfEvaluation" key="999">
                            <el-input style="width: 1000px;" type="textarea" v-model="ruleForm.selfEvaluation"
                                placeholder="编辑自我评价" maxlength="100" clearable :show-word-limit="true"></el-input>
                        </el-form-item>
                        <el-form-item label="公开简历" prop="saveState" key="99">
                            <el-radio-group v-model="ruleForm.saveState">
                                <el-radio label="N">是</el-radio>
                                <el-radio label="S">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="w--100 f-c-c">
                    <div v-if="type == 'add'" class="f-c-c saveBtn" @click="addForumResume()">保存新增</div>
                    <div v-else class="f-c-c saveBtn" @click="reviseMyForumResume()">确认修改</div>
                </div>
            </div>

        </div>
        <!-- 新增工作经历 -->
        <Dialog title="新增工作经历" width="700px" :visible="workVisibleVisible" @closeDialog="workVisibleVisible = false"
            class="workDialog">
            <div class="f-cl-c">
                <el-form class="f-w" ref="workRuleForm" :model="workRuleForm" label-width="80px"
                    :rules="workExperience">
                    <el-form-item :label="item.label" :prop="item.prop" v-for="(item, index) of workElFormItemArr"
                        :key="index">
                        <el-select style="width: 450px;" v-if="item.isSelect" v-model="workRuleForm[item.prop]">
                            <el-option v-for="item1 in item.optionList" :key="item1.name" :label="item1.name"
                                :value="item1.value"></el-option>
                        </el-select>
                        <div v-else-if="item.prop == 'salary'">
                            <salarySelsect @handlesalarySelsect="handleworksalarySelsect" :min="workRuleForm.minsalary"
                                :max="workRuleForm.maxsalary" :key="new Date().getTime() + 1">
                            </salarySelsect>
                        </div>
                        <ChooseSecondLevelCity style="width: 450px !important;" v-else-if="item.isCity"
                            :city="workRuleForm.workLocationList" @handleChangeCity="handleChangeworkCity"
                            :key="new Date().getTime() + 1">
                        </ChooseSecondLevelCity>
                        <div v-else-if="item.isDatePicker" class="flex" style="width: 450px;">
                            <el-date-picker v-model="workRuleForm.intoDate" type="date" placeholder="入职时间"
                                value-format="yyyy-MM-dd">
                            </el-date-picker>
                            <span>--</span>
                            <el-date-picker v-model="workRuleForm.outDate" type="date" placeholder="离职时间"
                                value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </div>
                        <el-input v-else-if="item.ifTextarea" style="width: 450px;" type="textarea"
                            :placeholder="item.placeholderValue" v-model="workRuleForm[item.prop]" maxlength="500"
                            show-word-limit>
                        </el-input>
                        <el-input style="width: 450px;" v-else maxlength="30" type="text"
                            v-model="workRuleForm[item.prop]" :placeholder="item.placeholderValue"></el-input>
                    </el-form-item>
                </el-form>
                <div class="confirmBtn" @click="addworkRuleForm">确认新增</div>

            </div>
        </Dialog>

        <!-- 新增教育背景 -->
        <Dialog title="新增教育背景" width="700px" :visible="educationVisibleVisible"
            @closeDialog="educationVisibleVisible = false" class="educationDialog">
            <div class="f-cl-c">
                <el-form class="f-w" ref="educationRuleForm" :model="educationRuleForm" label-width="80px"
                    :rules="educationExperience">
                    <el-form-item :label="item.label" :prop="item.prop" v-for="(item, index) of educationElFormItemArr"
                        :key="index">
                        <el-select style="width: 450px;" v-if="item.isSelect" v-model="educationRuleForm[item.prop]">
                            <el-option v-for="item1 in item.optionList" :key="item1.name" :label="item1.name"
                                :value="item1.value"></el-option>
                        </el-select>
                        <!-- <div v-else-if="item.prop == 'salary'">
                            <salarySelsect @handlesalarySelsect="handleeducasalarySelsect" :min="educationRuleForm.minsalary" :max="educationRuleForm.maxsalary" :key="new Date().getTime() + 2">
                            </salarySelsect>
                        </div> -->
                        <div v-else-if="item.isDatePicker" class="flex" style="width: 450px;">
                            <el-date-picker v-model="educationRuleForm.intakeDate" type="date" placeholder="入学时间"
                                value-format="yyyy-MM-dd">
                            </el-date-picker>
                            <span>--</span>
                            <el-date-picker v-model="educationRuleForm.graduateDate" type="date" placeholder="毕业时间"
                                value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </div>
                        <el-input style="width: 450px;" v-else maxlength="30" type="text"
                            v-model="educationRuleForm[item.prop]" :placeholder="item.placeholderValue"></el-input>
                    </el-form-item>
                </el-form>
                <div class="confirmBtn" @click="addeducationRuleForm">确认新增</div>
            </div>
        </Dialog>
    </div>
</template>
<script>
import { fillResume } from "@/utils/FormRules";
import { workExperience } from "@/utils/FormRules";
import { educationExperience } from "@/utils/FormRules";
import { UnitAccount } from "@/utils/attributeValue1Options";
export default {
    name: 'ControlsResume',
    data() {
        return {
            disabledChange: false,
            fillResume,  // 简历规则
            ruleForm: {  // 简历内容
                name: '',  // 名字
                position: '',  // 职位
                jobHunt: '',  // 在职状态
                workLocation: '', // 工作城市
                workLocationList: [],
                age: '',  // 年龄
                sex: '男',  // 性别
                contactType: 'P',  // 联系方式
                contactValue: '', // 联系方式值
                selfEvaluation: '', // 个人评价
                salary: '',  // 薪资
                maxsalary: '',  // 薪资
                minsalary: '',  // 薪资
                saveState: 'N',
                forumEducationList: [], // 教育经历
                forumExperienceList: [], // 工作经历
            },
            elFormItemArr: [
                {
                    label: '名字',
                    prop: 'name',
                    placeholderValue: '请输入名字',
                    is30WideCharacter: true
                },
                {
                    label: '职位',
                    prop: 'position',
                    placeholderValue: '请输入职位',
                    is30WideCharacter: true
                },
                {
                    label: '在职状态',
                    prop: 'jobHunt',
                    isSelect: true,
                    optionList: [
                        {
                            value: 1,
                            name: '离职-找工作'
                        }, {
                            value: 2,
                            name: '在职-观望中'
                        }, {
                            value: 3,
                            name: '在职-不跳槽'
                        }
                    ]
                },
                {
                    label: '薪资',
                    prop: 'salary',
                },
            ],
            infoElFormItemArr: [
                {
                    label: '年龄',
                    prop: 'age',
                    placeholderValue: '请输入年龄（最大年龄为60岁）',
                    is30WideCharacter: true
                },
                {
                    label: '性别',
                    prop: 'sex',
                },
                {
                    label: '联系方式',
                    prop: 'contactValue',
                    contactTypeList: [
                        {
                            value: 'P',
                            name: '手机',
                            imgPath: '../../../assets/Community/iPhoneIcon.png'
                        }, {
                            value: 'W',
                            name: '微信',
                            imgPath: '@/assets/Community/WeChatIcon.png'
                        }, {
                            value: 'Q',
                            name: 'QQ',
                            imgPath: '@/assets/Community/QQIcon.png'
                        }, {
                            value: 'M',
                            name: '邮箱',
                            imgPath: '@/assets/Community/MailboxIcon.png'
                        }
                    ],
                },
                {
                    label: '所在城市',
                    prop: 'workLocation',
                    isCity: true
                }
            ],
            workVisibleVisible: false,
            workRuleForm: {  // 工作经历内容
                corporateName: '',
                position: '',
                worktime: this.intoDate + this.outDate,
                intoDate: '',
                outDate: '',
                salary: '',
                minsalary: '',
                maxsalary: '',
                workLocation: '',
                workLocationList: '',
                responsibility: '',
                performance: '',
            },
            workExperience, // 工作经历规则
            workElFormItemArr: [
                {
                    label: '公司名称',
                    prop: 'corporateName',
                    placeholderValue: '请输入公司名称',
                    is30WideCharacter: true
                },
                {
                    label: '职位名称',
                    prop: 'position',
                    placeholderValue: '请输入职位名称',
                    is30WideCharacter: true
                },
                {
                    label: '在职时间',
                    prop: 'worktime',
                    isDatePicker: true
                },
                {
                    label: '岗位薪资',
                    prop: 'salary',
                },
                {
                    label: '所在城市',
                    prop: 'workLocation',
                    isCity: true
                },
                {
                    label: '岗位职责',
                    prop: 'responsibility',
                    placeholderValue: '请输入岗位职责',
                    ifTextarea: true
                },
                {
                    label: '工作业绩',
                    prop: 'performance',
                    placeholderValue: '请输入工作业绩',
                    ifTextarea: true
                }
            ],
            forumExperienceList: [],  //工作经历列表
            educationVisibleVisible: false,
            addCustomerDialog: false,
            educationRuleForm: {  // 教育内容
                degree: '',  // 学历
                degreeVal: '',  // 学历
                graduateDate: '',  // 毕业
                intakeDate: '',  // 入学
                schoolName: '',  // 学校名称
                speciality: ''   // 专业
            },
            educationExperience,  // 教育经历规则
            educationElFormItemArr: [
                {
                    label: '学校',
                    prop: 'schoolName',
                    placeholderValue: '请输入学校名称',
                    is30WideCharacter: true
                },
                {
                    label: '学历',
                    prop: 'degree',
                    placeholderValue: '请选择学历',
                    isSelect: true,
                    optionList: [
                        {
                            value: 1,
                            name: '小学'
                        }, {
                            value: 2,
                            name: '初中'
                        }, {
                            value: 3,
                            name: '高中'
                        }, {
                            value: 4,
                            name: '中专'
                        }, {
                            value: 5,
                            name: '大专'
                        }, {
                            value: 6,
                            name: '本科'
                        }, {
                            value: 7,
                            name: '硕士'
                        }, {
                            value: 8,
                            name: '博士'
                        }
                    ]
                },
                {
                    label: '时间',
                    prop: 'time',
                    isDatePicker: true
                },
                {
                    label: '专业',
                    prop: 'speciality',
                    placeholderValue: '请输入专业名称',
                    is30WideCharacter: true
                }
            ],
            educationList: [],  //教育经历列表
            type: '',
            resumeId:'',
        }
    },

    created() {
        let { resumeDetails, type } = JSON.parse(this.$UrlEncode.decode(this.$route.query.key));
        this.type = type;
        if (type == 'modify') {
            let { name, position, jobHunt, age, sex, contactType, contactValue, selfEvaluation, deleteFlag, salary, workLocation, forumEducation, forumExperience,resumeId } = resumeDetails;
            let salaryList = salary.split(" - ");
            let workLocationList = workLocation.split(" ");
            forumEducation.forEach((item) => {
                item.degreeVal = UnitAccount.getDegreetype(item.degree);
            })
            this.educationList = forumEducation;
            this.resumeId = resumeId;
            this.forumExperienceList = forumExperience;
            this.ruleForm = {
                name,
                position,
                jobHunt,
                age,
                sex,
                contactType,
                contactValue,
                selfEvaluation,
                saveState: deleteFlag,
                salary: salary,
                maxsalary: salaryList[1],
                minsalary: salaryList[0],
                workLocation,
                workLocationList,
                forumEducationList: forumEducation,
                forumExperienceList: forumExperience,
            }
        }
    },
    methods: {
        // 选中省市区
        handleChangeCity(value) {
            this.ruleForm.workLocation = value.join(' ');
            this.ruleForm.workLocationList = value;
        },
        handleChangeworkCity(value) {
            this.workRuleForm.workLocation = value.join(' ');
            this.workRuleForm.workLocationList = value;
        },
        handlesalarySelsect(value, min, max) {
            this.ruleForm.salary = value;
            this.ruleForm.minsalary = min;
            this.ruleForm.maxsalary = max;
        },
        handleworksalarySelsect(value, min, max) {
            this.workRuleForm.salary = value;
            this.workRuleForm.minsalary = min;
            this.workRuleForm.maxsalary = max;
        },
        addworkRuleForm() {
            let that = this;
            that.$refs.workRuleForm.validate((valid) => {
                if (valid) {
                    that.forumExperienceList.push(that.workRuleForm);
                    that.workRuleForm = {  // 工作经历内容
                        corporateName: '',
                        position: '',
                        intoDate: '',
                        outDate: '',
                        salary: '',
                        workLocation: '',
                        responsibility: '',
                        performance: '',
                    }
                    that.ruleForm.forumExperienceList = that.forumExperienceList;
                    that.workVisibleVisible = false;
                } else {
                    return false;
                }
            });
        },
        addeducationRuleForm() {
            let that = this;
            that.$refs.educationRuleForm.validate((valid) => {
                if (valid) {
                    that.educationList.push(that.educationRuleForm);
                    that.educationList.forEach((item) => {
                        item.degreeVal = UnitAccount.getDegreetype(item.degree);
                    })
                    that.educationRuleForm = {  // 教育内容
                        degree: '',  // 学历
                        graduateDate: '',  // 毕业
                        intakeDate: '',  // 入学
                        schoolName: '',  // 学校名称
                        speciality: ''   // 专业
                    }
                    
                    that.ruleForm.forumEducationList = that.educationList;
                    that.educationVisibleVisible = false;
                } else {
                    return false;
                }
            }); 
        },
        // 新增简历
        addForumResume() {
            let that = this,
                ruleFormValid = '',
                basicInfoValid = '';
            that.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    ruleFormValid = valid;
                } else {
                    return false;
                }
            });
            that.$refs.basicInfo.validate((valid) => {
                if (valid) {
                    basicInfoValid = valid;
                } else {
                    return false;
                }
            });
            if (ruleFormValid && basicInfoValid) {
                let data = that.ruleForm;
                data.forumEducationList.forEach((item)=>{
                  delete item.degreeVal
                })
                data.workAge = that.ruleForm.forumExperienceList && that.ruleForm.forumExperienceList.length ? that.getWorkAge() : 0;
                data.forumExperienceList.forEach((item2)=>{
                  delete item2.end
                  delete item2.start
                })

                delete data.maxsalary;
                delete data.minsalary;
                delete data.workLocationList;
                data.topDegree = that.getTopDegree();
                that.$confirm('确定新增？', '提示').then(() => {
                    that.$http.addForumResume({
                        ...data,
                        userId: parseInt(that.$store.state.userId),
                        token: that.$getStorage('token'),
                    }).then(res => {
                        that.$common.message(res.msg, 'success')
                        that.$router.go(-1);
                    })
                }).catch(() => { })
            }
        },
        reviseMyForumResume() {
            let that = this,
                ruleFormValid = '',
                basicInfoValid = '';
            that.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    ruleFormValid = valid;
                } else {
                    return false;
                }
            });
            that.$refs.basicInfo.validate((valid) => {
                if (valid) {
                    basicInfoValid = valid;
                } else {
                    return false;
                }
            });
            if (ruleFormValid && basicInfoValid) {
                let data = that.ruleForm;
                data.forumEducationList.forEach((item)=>{
                  delete item.degreeVal
                })
                data.workAge = that.ruleForm.forumExperienceList && that.ruleForm.forumExperienceList.length ? that.getWorkAge() : 0;
                data.forumExperienceList.forEach((item2)=>{
                  console.log(item2);
                  delete item2.end
                  delete item2.start
                })
                delete data.maxsalary;
                delete data.minsalary;
                delete data.workLocationList;
                
                data.topDegree = that.getTopDegree();
                data.resumeId = that.resumeId;
                // console.log(data);
                that.$confirm('确定修改？', '提示').then(() => {
                    that.$http.reviseMyForumResume({
                        ...data,
                        userId: parseInt(that.$store.state.userId),
                        token: that.$getStorage('token'),
                    }).then(res => {
                        that.$common.message(res.msg, 'success')
                        that.$router.go(-1);
                    })
                }).catch(() => { })
            }
        },
        // 获取最高学历
        getTopDegree() {
            let that = this,
                forumEducationList = that.ruleForm.forumEducationList,
                DegreeList = [],
                MaxDegree = 0
            forumEducationList.forEach((item) => {
                DegreeList.push(item.degree)
            })
            DegreeList.sort((a, b) => a - b);
            MaxDegree = DegreeList[DegreeList.length - 1];
            return MaxDegree
        },
        // 获取工作年限
        getWorkAge() {
          let that = this,
            forumExperienceList = this.ruleForm.forumExperienceList;
          forumExperienceList.forEach((item) => {
            item.start = parseInt(item.intoDate.split('-')[0])
            item.end = parseInt(item.outDate.split('-')[0])
          })
          return that.countWorkAge(forumExperienceList)
        },
        // 计算工作年限
        countWorkAge(work) {
          let index = 0,
            work_ages = 0,
            work_count = work.length - 1,
            time_s = work[index].start,
            time_e = work[index].end;
          while (index < work_count) {
            if ((work[index + 1].start >= time_s && work[index + 1].start <= time_e) || (work[index + 1].end <=
                time_s && work[index + 1].end >= time_e)) {
              if (time_s > work[index + 1].start) {
                time_s = work[index + 1].start;
              }
              if (time_e < work[index + 1].end) {
                time_e = work[index + 1].end;
              }
            } else {
              work_ages = work_ages + (time_e - time_s);
              time_s = work[index + 1].start;
              time_e = work[index + 1].end;
            }
            index++;
          }
          work_ages = work_ages + (time_e - time_s);
          return work_ages
        },
    },
}

</script>
<style lang='less' scoped>
.ControlsResume {
    .head {
        padding: 20px 0;
        margin-bottom: 35px;
        border-bottom: 1px solid #F7F7F8;

        .LeftPart {
            width: 55px;
            cursor: pointer;

            .logo {
                width: 53px;
                height: 53px;
                margin-right: 18px;
            }

            .shiliaologo {
                width: 77px;
                height: 38px;
            }

        }

        .el-breadcrumb ::v-deep .el-breadcrumb__inner {
            color: #666666 !important;
            font-weight: 400 !important;
            font-size: 14px;
        }

        .el-breadcrumb__item:last-child ::v-deep .el-breadcrumb__inner {
            color: #0363FA !important;
            font-weight: 400 !important;
            font-size: 14px;
        }
    }

    .main {
        .formTitle {
            width: 100%;
            margin-bottom: 22px;
            border-left: 4px solid #0363FA;
            padding-left: 10px;
            font-size: 24px;
            font-weight: 500;
            color: #333333;
        }

        .forumExperienceItem {
            width: 1050px;
            margin-left: 1px;
            border-radius: 20px;
            padding: 25px 35px 25px 35px;
        }

        .addBtn {
            width: 150px;
            font-size: 16px;
            font-weight: 400;
            color: #0363FA;
            cursor: pointer;
        }

        .saveBtn {
            width: 160px;
            height: 50px;
            border-radius: 10px;
            background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
            color: #FFF;
            line-height: 50px;
            text-align: center;
            font-size: 16px;
            cursor: pointer;
        }

    }

    .workDialog {
        /deep/.el-dialog__title {
            color: #333333;
            font-size: 30px;
        }

        .confirmBtn {
            width: 160px;
            height: 50px;
            border-radius: 10px;
            background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
            color: #FFF;
            line-height: 50px;
            text-align: center;
            font-size: 16px;
            cursor: pointer;
        }
    }

    .educationDialog {
        /deep/.el-dialog__title {
            color: #333333;
            font-size: 30px;
        }

        .confirmBtn {
            width: 160px;
            height: 50px;
            border-radius: 10px;
            background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
            color: #FFF;
            line-height: 50px;
            text-align: center;
            font-size: 16px;
            cursor: pointer;
        }
    }
}
</style>